import Head from "next/head";
import { AppProps } from "next/app";
import { AnimatePresence, motion } from "framer-motion";

import { initSentry } from "@/util";
import { Navigation, Footer } from "@/components";

import "../styles/globals.css";
import "../styles/syntax.css";

initSentry();

const App: React.FC<AppProps> = ({
  Component,
  pageProps,
  router,
  // @ts-ignore err is not publicly exposed
  err,
}) => (
  <>
    <a href="#content" className="sr-only">Skip to main content</a>
    <Head>
      <title>Luke Brown</title>
    </Head>

    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial="initial"
        animate="enter"
        exit="exit"
        key={router.route}
        transition={{ duration: 0.2, type: "linear" }}
        variants={{
          initial: { opacity: 0 },
          enter: { opacity: 1 },
          exit: { opacity: 0 },
        }}
      >
        <div className="flex flex-col justify-between md:min-h-screen">
          <div>
            <Navigation />
            <div id="content">
              <Component {...pageProps} err={err} />
            </div>
          </div>
          <Footer />
        </div>
      </motion.div>
    </AnimatePresence>
  </>
);

export default App;
